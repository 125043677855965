import { latlngGraticule } from './plugins/Leaflet.Graticule';
import { mouseCoordinates } from './plugins/Leaflet.MouseCoordinates';
import noFishing from './no_fishing.json';
import norway250nm from './250nm.json';


const mapOptions = {
  preferCanvas: true,
  minZoom: 2,
  zoomControl: true,
  maxBounds: window.L.latLngBounds(
    window.L.latLng(-90, -200),
    window.L.latLng(90, 200)
  ),
  maxBoundsViscosity: 1,
  center: [60, -20],
  zoom: 4,
  layers: [
    window.L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }),
  ]
} as any;


/**
 * Performs all the dirty work on the EEZ map initialization.
 */
export function setUpMap(domId) {
  const map = window.L.map(domId, mapOptions);
  window.L.geoJSON(noFishing as any).addTo(map);
  window.L.geoJSON(norway250nm as any).addTo(map);

  window.L.tileLayer.wms('https://maps.fishfacts.com/geoserver/gwc/service/wms', {
    layers: 'zones:eez',
    format: 'image/png',
    transparent: true
  }).addTo(map);

  mouseCoordinates().addTo(map);

  latlngGraticule({
    showLabel: true,
    zoomInterval: [
      { start: 2, end: 3, interval: 30 },
      { start: 4, end: 4, interval: 10 },
      { start: 5, end: 7, interval: 5 },
      { start: 8, end: 10, interval: 1 }
    ]
  }).addTo(map);

  return map;
}


export const colorArray: string[] = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#E64D66',
  '#6666FF'
];
